
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  reactive,
  inject,
  watch,
} from "vue";
import CustomersMap from "@/components/map/CustomersMap.vue";
import CampaignsMap from "@/components/map/CampaignsMap.vue";

//import xcustomers from "@/core/data/correct_customers";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
import mitt from "mitt";
import { number } from "@intlify/core-base";

export default defineComponent({
  name: "DWmap",
  components: {
    CustomersMap,
    CampaignsMap,
  },

  props: {
    widgetClasses: String,
    mySource: String,
    myDealer: Number,
  },
  data() {
    return {
      isAddClass: false,
      ownChange: false,
      componentKey: ref(0),
      //customers: xcustomers,
      myCustomers:ref([]) as any,
      center: ref(null) as any,      
      refresh: ref(false),     
    };
  },  
  computed: {
    campaignCustomers() {
      
      var result = this.myCustomers;
      result = [];
      for (var i = 4; i < 12; i++) {
        result = result.concat(
          this.myCustomers.filter((x) => new Date(x.lastRoDate).getMonth() == i)
        );
      }
      return result;
    },
  },  
  watch: {    
    myDealer(newValue, oldvalue) {
      //console.log("Selected Source is now", this.mySource);
      console.log("Selected dealerid is now", newValue);
      if (this.mySource) this.fetchData(newValue, this.mySource);
    },
    mySource(newValue, oldvalue) {
      //console.log("Selected dealerid is now", this.myDealer);
      //console.log("Selected Source is now", newValue);
      if (this.myDealer) this.fetchData(this.myDealer, newValue);
    },
  }, 
  beforeUpdate() {
    if (this.ownChange) {
      this.ownChange = false;
      return;
    }            
  },

  methods: {
    expandmap: function () {
      this.isAddClass = this.isAddClass ? false : true;
    },
    fetchData(dealerId, mySource) {
      
      ApiService.axios
        .post(`/dealer/getDealerById`, { dealerId: dealerId, roleId: 0 })
        .then((res) => {
          this.center = [res.data.dealer.lat,res.data.dealer.lng];
          //console.log(this.center);
          this.componentKey += 1;
        })
        .catch((error) => {
          console.log(error);
        });

        this.myCustomers = [];

      ApiService.axios
        .get(
          `/Customer/GetCustomersForV3Map?dealerId=${dealerId}&dataSource=${mySource}`,
          {}
        )
        .then((res) => {
          this.myCustomers = res.data;
          console.log('customers='+this.myCustomers.length);
          this.componentKey += 1;
        })
        .catch((error) => {
          console.log(error);
        });               
    },
  },
});


import { defineComponent, ref, onMounted, computed, reactive} from "vue";
import Campaign from "@/components/map/DD-campaign.vue";
import TargetCustomers from "@/components/map/DD-targetcustomers.vue";
import CommonMap from "@/components/map/CommonMap.vue";

export default defineComponent({
  name: "CampaignsMap",
  components: {
    Campaign,
    TargetCustomers,
    CommonMap
  },

  props: ["customers"],

  data() {
    return {
      openedMarkerID: null,
      isAddClass: false,
      ownChange: false,
    };
  },

  setup(props) {        
    const center = ref({ lat: 27.99490794607894, lng: -82.50615465372297 });
    //const myMapRef = ref();
    const heatData = ref([]);
        
    var markers = ref(props.customers);       
    var customersByCampaign = props.customers;
    var customersByTarget = props.customers;

    return {      
      markers,
      center,
      heatData,
      customersByCampaign,
      customersByTarget,            
      refresh:ref(false)
    };
  },  
  
  beforeUpdate() {
    if (this.ownChange) {
      this.ownChange = false;
      return;
    }

    this.markers = this.customers;   
    this.customersByCampaign = this.customers;
    this.customersByTarget = this.customers;
    this.refresh = !this.refresh;
  },

  methods: {
    changeMarkersByCampaign(customerList) {
      this.customersByCampaign = customerList;
      this.changeMarkers();
    },

     changeMarkersByTarget(customerList) {
      this.customersByTarget = customerList;
      this.changeMarkers();
    },
    
    changeMarkers() {
      this.ownChange = true;          
      this.markers = this.customers.filter(
        (x) =>
          this.customersByCampaign.includes(x)
          && this.customersByTarget.includes(x)
      );  
    },
    resetAllFilters() {      
      this.markers = this.customers;
      this.customersByCampaign = this.customers;
      this.customersByTarget = this.customers;      
    },
  },
});


import {
  defineComponent,
  onMounted,
  onUpdated,
  readonly,
  ref,
  shallowReadonly,
  shallowRef,
} from "vue";
//import CustomerCard from "@/components/map/CustomerCard.vue";
//import { MenuComponent } from "@/assets/ts/components";
//import { setupContainsLatLng } from "@/core/helpers/is-point-within-polygon.js";
import areas from "@/core/data/areas";
//import { AnyMessageParams } from "yup/lib/types";
import ApiService from "@/core/services/ApiService";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
//import MarkerContainer from './marker.container.js';
//import ModalWindow from '@/components/map/ModalWindow.vue';
export default defineComponent({
  name: "CommonMap",
  props: ["markers", "center", "isHeatMap", "customers"],
  //components: { CustomerCard },//, LMap, LTileLayer, LMarker },
  //components: { ModalWindow },
  emits: ["zip-selected"],
  watch: {
    center() {
      this.updated();
    },

    markers() {
      this.updated();
    },
  },
  data() {
    return {
      myMarkers: ref([]) as any,
      myMap: ref(null) as any,
      marker: null as any,
      mainMarker: null as any,
      refreshInfoKey: ref(0),
    };
  },

  setup(props) {
    //console.log('props_markers=' + props.markers.length);
    //console.log('props_center=' + props.center);
    return {
      openedMarkerID: ref(null),
      myAreas: areas,
      mapCenterUrl: "media/svg/map/map-marker.svg",
      //,map
    };
  },

  methods: {
    updated() {      
      
      if (this.center == null || this.center.lat != undefined) {
        return;
      }

      var container: any;
      container = L.DomUtil.get("map");

      if (container == null) {
        return;
      }
      var centerIcon = L.icon({
        iconUrl: "media/svg/map/map-marker.svg",
        //shadowUrl: 'leaf-shadow.png',

        iconSize: [38, 95], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62], // the same for the shadow
        popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
      });

      var lat = this.center[0];
      var lng = this.center[1];
      var c = new L.LatLng(lat, lng);

      if (container._leaflet_id != undefined) {
        var myZoom = this.myMap.getZoom();
      }

      if (container._leaflet_id != undefined) {
        this.myMap.off();
        this.myMap.remove();
        this.myMap = L.map("map", {
          scrollWheelZoom: false,
          preferCanvas: true,
        }).setView(c, 12);
        this.myMap.setZoom(myZoom);
        this.myMap.removeLayer(this.mainMarker);
      } else {
        this.myMap = L.map("map", {
          scrollWheelZoom: false,
          preferCanvas: true,
        }).setView(c, 12);
      }

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: "© OpenStreetMap",
      }).addTo(this.myMap);

      var myRenderer = L.canvas({ padding: 0.5 });

      this.mainMarker = L.marker(c, { icon: centerIcon }).addTo(this.myMap);

      this.myMarkers = [];

      console.log("markers1=" + this.markers.length);

      this.markers.forEach((m) => {    
        if (m.lat == null || m.lng == null) {
          return;
        }    
        var cL = L.circleMarker(new L.LatLng(m.lat, m.lng), {
          renderer: myRenderer,
          color: m.markerColor,
        });

        var status = "Loyal";
        var statusStyle = "loyal";
        if (m.isFlagged) {
          status = "Flagged";
          statusStyle = "flagged";
        } else if (m.visits == 0) {
          status = "Lost";
          statusStyle = "lost";
        } else if (m.visits == 1) {
          status = "Retained";
          statusStyle = "retained";
        }

        cL.bindPopup(
          '<div role="dialog" tabindex="-1" class="gm-style-iw gm-style-iw-c" style="padding-right: 0px; padding-bottom: 0px;">' +
            '<div class="gm-style-iw-d" style="overflow: scroll; max-height: 400px;">' +
            '<div closeclick="true">' +
            '<a href="#" class="card border-0" tabindex="0">' +
            '<div class="card-header border-0 pt-1 pb-1 ps-5 d-flex flex-row ' +
            statusStyle +
            '"' +
            ">" +
            '<div class="card-title d-flex align-items-stretch justify-content-between flex-lg-grow-1 m-0">' +
            '<span class="col">Customer ID: <b class="fw900 fs-2">' +
            m.custNumber +
            '</b></span><span class="col text-end">Customer Status: <b class="fw900 fs-2">' +
            status +
            "</b></span>" +
            "</div>" +
            "</div>" +
            '<div class="card-body d-flex pt-2 ps-5 pe-5 pb-2">' +
            '<div class="col-7">' +
            '<div class="fs-3 fw-bolder text-dark"></div>' +
            '<p class="mt-1 mb-1">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Name:</span><span class="ifw fw-bolder fs-3 mt-0 mb-1 caps">' +
            m.firstname +
            " " +
            m.lastname +
            "</span>" +
            "</p>" +
            '<p class="mt-1 mb-1">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Vehicle:</span><span class="ifw fw-bolder fs-3 mt-0 mb-1 caps">' +
            m.year +
            " " +
            m.make +
            " " +
            m.model +
            "</span>" +
            "</p>" +
            '<div class="d-flex">' +
            '<p class="mt-1 mb-1 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Mileage:</span><span class="text-dark fw700 fs-5 mt-0 mb-1">' +
            m.miles +
            "</span>" +
            "</p>" +
            '<p class="mt-1 mb-1 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">VIN:</span><span class="text-dark fw700 fs-5 mt-0 mb-1">' +
            m.vin +
            "</span>" +
            "</p>" +
            "</div>" +
            '<p class="mt-1 mb-1">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Address:</span><span class="text-dark fw700 fs-5 mt-0 mb-1 add caps">' +
            m.address1 +
            '</span><br /><span class="text-dark fw700 fs-5 mt-0 mb-1 add caps">' +
            m.address2 +
            "</span>" +
            "</p>" +
            '<div class="d-flex">' +
            '<p class="mt-1 mb-1 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Home Phone:</span><span class="text-dark fw700 fs-5 mt-0 mb-1">' +
            m.phoneno +
            "</span>" +
            "</p>" +
            '<p class="mt-1 mb-1 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Mobile Phone:</span><span class="text-dark fw700 fs-7 mt-0 mb-1"></span>' +
            "</p>" +
            "</div>" +
            '<p class="mt-1 mb-1">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Email:</span><span class="text-dark fw700 fs-5 mt-0 mb-1">' +
            m.email +
            "</span>" +
            "</p>" +
            "</div>" +
            '<div class="col-5 bdr-left">' +
            '<div class="d-flex text-center">' +
            '<p class="mt-1 mb-1 col ps-4">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Distance:</span><span class="ifw fw-bolder fs-4 mt-0 mb-1">' +
            m.distance +
            " Miles</span>" +
            "</p>" +
            '<p class="mt-1 mb-1 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Visits (1 Year):</span><span class="ifw fw-bolder fs-4 mt-0 mb-1">' +
            m.visits +
            " Visits</span>" +
            "</p>" +
            "</div>" +
            '<div class="d-flex text-center">' +
            '<p class="mt-1 mb-1 col ps-4">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Last RO Date:</span><span class="ifw fw-bolder fs-4 mt-0 mb-1">' +
            m.lastRoDate +
            "</span>" +
            "</p>" +
            '<p class="mt-1 mb-1 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Last RO $:</span><span class="ifw fw-bolder fs-4 mt-0 mb-1">' +
            "$ 500.23" +
            "</span>" +
            "</p>" +
            "</div>" +
            '<div class="d-flex text-center">' +
            '<p class="mt-1 ms-4 mb-1 ps-5">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub text-center">Household Vehicles:</span><span class="ifw fw-bolder fs-4 mt-0 mb-1">' +
            m.vin +
            "</span>" +
            "</p>" +
            "</div>" +
            '<p class="mt-2 ms-5 mb-1 bdr-top bdr-bottom text-center p-4">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Lifetime Service Value:</span><span class="ifwt fw-bolder mt-0 mb-1">' +
            "$ 3253.74" +
            "</span>" +
            "</p>" +
            '<div class="d-flex text-center">' +
            '<p class="mt-1 mb-0 col ps-4">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Targeted: <img src="media/svg/map/yes.svg" width="20"/></span>' +
            "</p>" +
            '<p class="mt-1 mb-0 col">' +
            '<span class="text-gray-400 fs-7 mt-1 mb-0 desc-sub">Matched: <img src="media/svg/map/no.svg" width="20"/></span>' +
            "</p>" +
            "</div>" +
            '<p class="mt-0 ms-2 mb-1 text-center p-4">' +
            '<span class="match-col fs-9 mt-0 mb-0 desc-sub">Target = We Mailed | Match = They Came In</span>' +
            "</p>" +
            "</div>" +
            "</div>" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          { closeButton: false }
        );

        this.myMarkers.push(cL.addTo(this.myMap));
      });

      this.myMap.panTo(c);
    },

    updatePolygons(zip) {
      var howMany = this.customers?.filter((x) => x.Zip == zip).length;

      if (howMany <= 20) {
        return "#8A2BE2";
      }

      if (howMany <= 40) {
        return "#4169E1";
      }

      if (howMany <= 60) {
        return "#0000CD";
      }

      if (howMany <= 100) {
        return "#0000FF";
      }

      return "#C5B358";
    },

    openMarker(id) {
      if (id) {
        ApiService.axios
          .get(`Customer/GetCustomersForV3ById?customerId=${id}`)
          .then((res) => {
            this.marker = res.data;
            this.refreshInfoKey += 1;
            this.openedMarkerID = id;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    handleClick(event) {
      if (event.latLng?.lat) {
        (this.$refs.polygons as any)?.forEach((x, index) => {
          x.$polygonPromise.then((res) => {
            let isWithinPolygon = res.containsLatLng(
              event.latLng.lat(),
              event.latLng.lng()
            );
            if (isWithinPolygon) {
              //console.log(x.$ref);
              //x.$polygonObject.setOptions({fillColor: "#FF0000", fillOpacity: 0.35});
              this.$emit("zip-selected", (this.$refs.zips as any)[index].id);
            }
          });
        });
      }
    },
  },
});

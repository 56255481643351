
import { defineComponent, ref, onMounted, computed, reactive } from "vue";
import CustomerStatus from "@/components/map/DD-cs.vue";
import Years from "@/components/map/DD-year.vue";
import Models from "@/components/map/DD-models.vue";
import LastRO from "@/components/map/DD-lastro.vue";
import ZipRadius from "@/components/map/DD-ziprad.vue";
import CommonMap from "@/components/map/CommonMap.vue";
import { MenuComponent } from "@/assets/ts/components";
import ZipCard from "@/components/map/ZipCard.vue";
import dateFuncs from "@/core/helpers/dateFuncs";

export default defineComponent({
  name: "CustomersMap",
  components: {
    CustomerStatus,
    Years,
    Models,
    LastRO,
    ZipRadius,
    CommonMap,
    ZipCard,
  },

  props: ["customers", "center"],

  data() {
    return {
      openedMarkerID: null,
      isAddClass: false,
      ownChange: false,
      root: [],
    };
  },

  watch: {
    center: function (val) {
      this.myCenter = val;
      //console.log(this.center);
    },
    /*
      customers:function(val) {
        this.myCustomers = val;        
      }
      */
  },

  setup(props) {
    /*
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    */

    var myCenter = props.center; //ref({ lat: 39.6157989501953, lng: -104.874000549316 });
    const heatData = ref([]);
    //var myCustomers = ref(props.customers);
    var markers = ref(props.customers);    
    var customersByModel = props.customers;
    var customersByYear = props.customers;
    var customersByRo = props.customers;
    var customersByStatus = props.customers;
    var customersByMailed = props.customers;
    var customersByDistance = props.customers;
    var customersByCampaign = props.customers;
    var customersByTarget = props.customers;

    return {
      //isAddClass,
      markers,
      myCenter,
      heatData,
      customersByModel,
      customersByYear,
      customersByRo,
      customersByStatus,
      customersByMailed,
      customersByDistance,
      customersByCampaign,
      customersByTarget,
      refresh: ref(false),
      isHeatMapOn: ref(false),
      modalVisible: ref(false),
      modalCustomers: null,
      modalZip: ""      
    };
  },

  beforeUpdate() {
    if (this.ownChange) {
      this.ownChange = false;
      return;
    }

    this.markers = this.customers;
    this.customersByModel = this.customers;
    this.customersByYear = this.customers;
    this.customersByRo = this.customers;
    this.customersByStatus = this.customers;
    this.customersByMailed = this.customers;
    this.customersByDistance = this.customers;
    this.refresh = !this.refresh;
  },

  methods: {
    openMarker(id) {
      this.openedMarkerID = id;
    },

    changeMarkersByStatus(customerList) {
      this.customersByStatus = customerList;
      this.root = customerList;
      this.changeMarkers();
    },

    changeMarkersByYear(customerList) {
      this.customersByYear = customerList;
      this.root = customerList;
      this.changeMarkers();
    },

    changeMarkersByModel(customerList) {
      this.customersByModel = customerList;
      this.root = customerList;
      this.changeMarkers();
    },

    changeMarkersByRo(customerList) {
      this.customersByRo = customerList;
      this.root = customerList;
      this.changeMarkers();
    },

    changeMarkersByDistance(customerList) {
      this.customersByDistance = customerList;
      this.root = customerList;
      this.changeMarkers();
    },

    changeMarkersByMailed() {
      if (this.customersByMailed == this.customers) {
        var currentDate = new Date();
        this.customersByMailed = this.customers.filter(
          (x) =>
            (x?.firstName?.startsWith("A", 0) ||
              x?.firstName?.startsWith("G", 0) ||
              x?.firstName?.startsWith("O", 0)) &&
            x.visits > 1 &&
            x.isFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.year) > 12
        );
      } else {
        this.customersByMailed = this.customers;
      }
      //this.changeMarkers();
    },

    changeMarkers() {
      this.ownChange = true;

      if (
        this.root != this.customersByStatus &&
        this.customersByStatus.length != this.customers.length
      ) {
        this.root = this.root.filter((x) => this.customersByStatus.includes(x));
      }
      if (
        this.root != this.customersByYear &&
        this.customersByYear.length != this.customers.length
      ) {
        this.root = this.root.filter((x) => this.customersByYear.includes(x));
      }
      if (
        this.root != this.customersByModel &&
        this.customersByModel.length != this.customers.length
      ) {
        this.root = this.root.filter((x) => this.customersByModel.includes(x));
      }

      if (
        this.root != this.customersByRo &&
        this.customersByRo.length != this.customers.length
      ) {
        this.root = this.root.filter((x) => this.customersByRo.includes(x));
      }

      if (
        this.root != this.customersByDistance &&
        this.customersByDistance.length != this.customers.length
      ) {
        this.root = this.root.filter((x) =>
          this.customersByDistance.includes(x)
        );
      }

      if (
        this.root != this.customersByMailed &&
        this.customersByMailed.length != this.customers.length
      ) {
        this.root = this.root.filter((x) => this.customersByMailed.includes(x));
      }      
      this.markers = this.root;
    },

    resetAllFilters() {
      this.markers = this.customers;
    },

    toggleHeatMap() {
      this.isHeatMapOn = !this.isHeatMapOn;
      this.ownChange = true;
    },

    showCustomerModal(zip: string) {
      //console.log('zip='+zip);
      this.modalZip = zip;
      this.modalCustomers = this.customers.filter((x) => x.zip == zip);
      this.modalVisible = true;
      this.ownChange = true;
    },
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-0 gx-xl-8 mb-0 mt-0" }
const _hoisted_2 = { class: "col-xxl-4 mb-5" }
const _hoisted_3 = { class: "col-xxl-4 mb-5" }
const _hoisted_4 = { class: "col-xxl-4 mb-5" }
const _hoisted_5 = { class: "row gy-3 gx-xl-8 mt-1 mb-3" }
const _hoisted_6 = { class: "col-xxl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerData = _resolveComponent("CustomerData")!
  const _component_CustomerRetention = _resolveComponent("CustomerRetention")!
  const _component_FlaggedRecords = _resolveComponent("FlaggedRecords")!
  const _component_DWmap = _resolveComponent("DWmap")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_component_CustomerData, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          customerCharts: _ctx.customerCharts,
          key: _ctx.refreshKey
        }, null, 8, ["customerCharts"]))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CustomerRetention, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          customers: _ctx.myCustomers
        }, null, 8, ["customers"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createBlock(_component_FlaggedRecords, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          customerCharts: _ctx.customerCharts,
          key: _ctx.refreshKey
        }, null, 8, ["customerCharts"]))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_DWmap, {
          mySource: _ctx.mySource,
          myDealer: _ctx.myDealer,
          "widget-classes": "card-xxl-stretch mb-xl-3"
        }, null, 8, ["mySource", "myDealer"])
      ])
    ])
  ], 64))
}
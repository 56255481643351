
import { defineComponent } from "vue";
import ListedItem from "@/core/helpers/listeditem";
import dateFuncs from "@/core/helpers/dateFuncs";

const labels = [
  "Lost - 0 Visits",
  "Retained - 1 Visit",
  "Loyal - 2+ Visits",
  "Flagged",
  "Sold - No Service",
];

export default defineComponent({
  name: "DD-targetcustomers",
  components: {},
  props: ["customers", "markers","refresh"],
  emits: ["customers-by-target"],

  watch: {         
      	refresh: function(newVal, oldVal) { // watch it
          this.statusList.forEach(x => x.active = false);
        }},

  data() {
    return {
      statusList: Array<ListedItem>(),
      calledFromOwn: false,
    };
  },

  created: function () {
    var currentDate = new Date();
    this.statusList.push(
      new ListedItem(
        labels[0],
        this.customers.filter(
          (x) =>
            x.Visits == 0 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) > 12
        ).length
      )
    );
    this.statusList.push(
      new ListedItem(
        labels[1],
        this.customers.filter(
          (x) =>
            x.Visits == 1 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) > 12
        ).length
      )
    );
    this.statusList.push(
      new ListedItem(
        labels[2],
        this.customers.filter(
          (x) =>
            x.Visits > 1 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) > 12
        ).length
      )
    );
    this.statusList.push(
      new ListedItem(
        labels[3],
        this.customers.filter((x) => x.IsFlagged == 1).length
      )
    );
    this.statusList.push(
      new ListedItem(
        labels[4],
        this.customers.filter(
          (x) =>
            x.Visits == 0 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) <= 12
        ).length
      )
    );
  },

  beforeUpdate() {    
    var currentDate = new Date();
    if (!this.calledFromOwn) {
      this.statusList[0].quantity = this.markers.filter(
        (x) =>
          x.Visits == 0 &&
          x.IsFlagged == 0 &&
          dateFuncs.inMonths(currentDate, x.Year) > 12
      ).length;

      this.statusList[1].quantity = this.markers.filter(
        (x) =>
          x.Visits == 1 &&
          x.IsFlagged == 0 &&
          dateFuncs.inMonths(currentDate, x.Year) > 12
      ).length;

      this.statusList[2].quantity = this.markers.filter(
        (x) =>
          x.Visits > 1 &&
          x.IsFlagged == 0 &&
          dateFuncs.inMonths(currentDate, x.Year) > 12
      ).length;

      this.statusList[3].quantity = this.markers.filter(
        (x) => x.IsFlagged == 1
      ).length;

      this.statusList[4].quantity = this.markers.filter(
        (x) =>
          x.Visits == 0 &&
          x.IsFlagged == 0 &&
          dateFuncs.inMonths(currentDate, x.Year) <= 12
      ).length;
    } else {
      this.calledFromOwn = false;     
    }
    this.statusList.forEach((x) => {
      if (x.quantity == 0) {
        x.active = false;
      }
    });
  },

  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;

      var node = this.statusList.find((x) => x.label == label);
      if (node == null) {
        return;
      }
      //node is always not null but if this condition is omitted then js produces errors below
      node.active = !node.active;

      if (this.statusList.every((x) => !x.active)) {
        this.calledFromOwn = false;
        this.$emit("customers-by-target", this.customers);
        return;
      }

      var currentDate = new Date();
      var result = [];

      this.statusList.forEach((x) => {
        if (x.label == labels[0] && x.active) {
          result = result.concat(
            this.customers.filter(
              (x) =>
                x.Visits == 0 &&
                x.IsFlagged == 0 &&
                dateFuncs.inMonths(currentDate, x.Year) > 12
            )
          );
          return;
        }

        if (x.label == labels[1] && x.active) {
          result = result.concat(
            this.customers.filter(
              (x) =>
                x.Visits == 1 &&
                x.IsFlagged == 0 &&
                dateFuncs.inMonths(currentDate, x.Year) > 12
            )
          );
          return;
        }

        if (x.label == labels[2] && x.active) {
          result = result.concat(
            this.customers.filter(
              (x) =>
                x.Visits > 1 &&
                x.IsFlagged == 0 &&
                dateFuncs.inMonths(currentDate, x.Year) > 12
            )
          );
          return;
        }

        if (x.label == labels[3] && x.active) {
          result = result.concat(
            this.customers.filter((x) => x.IsFlagged == 1)
          );
          return;
        }

        if (x.label == labels[4] && x.active) {
          result = result.concat(
            this.customers.filter(
              (x) =>
                x.Visits == 0 &&
                x.IsFlagged == 0 &&
                dateFuncs.inMonths(currentDate, x.Year) <= 12
            )
          );
          return;
        }
      });
        if (result.length == 0)
      {
        this.calledFromOwn = false;
        result = this.customers;
      }
      this.$emit("customers-by-target", result);
    },
  },
});


import { defineComponent, ref, toRef } from "vue";
import ListedItem from "@/core/helpers/listeditem";

export default defineComponent({
  name: "DD-models",
  components: {},
  props: ["customers", "markers", "refresh"],
  emits: ["customers-by-model"],

  watch: {
    refresh: function (newVal, oldVal) {
      // watch it
      this.models.forEach((x) => (x.active = false));
    },
  },

  data() {
    return {
      models: Array<ListedItem>(),
      calledFromOwn: true,
    };
  },

  beforeUpdate() {
    if (!this.calledFromOwn) {
      var models2 = new Array<ListedItem>();
      var uniqueModels = new Set<string>(this.customers.map((x) => x.model));
      uniqueModels.forEach((x) => {
        models2.push(new ListedItem(x, this.markers.filter(m => m.model == x).length, this.models.find(m => m.label == x)?.active));
      });
      models2 = models2.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
      this.models = models2;     
    } else {
      this.calledFromOwn = false;
    }
  },

  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;
      var node = this.models.find((x) => x.label == label);
      if (node == null) {
        return;
      }
      //node is always not null but if this condition is omitted then js produces errors below
      node.active = !node.active;

      if (this.models.every((x) => !x.active || x.quantity == 0)) {
        this.calledFromOwn = false;
        this.$emit("customers-by-model", this.customers);
        return;
      }

      var result = [];
      this.models.forEach((x) => {
        if (x.active == true) {
          result = result.concat(
            this.customers.filter((y) => y.model == x.label)
          );
        }
      });
      /*
      if (result.length == 0) {
        this.calledFromOwn = false;
        result = this.customers;
      }
      */
      this.$emit("customers-by-model", result);
    },
  },
});

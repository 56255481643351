import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-250px p-5",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = ["onChange"]
const _hoisted_4 = ["checked", "disabled"]
const _hoisted_5 = { class: "form-check-label fw-bolder fs-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.miles, (x, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "form-check form-check-custom form-check-solid form-check-sm",
          key: index,
          onChange: ($event: any) => (_ctx.checkHandler(x.label))
        }, [
          _createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            value: "",
            checked: x.active  && x.quantity > 0,
            disabled: x.quantity == 0
          }, null, 8, _hoisted_4),
          _createElementVNode("label", _hoisted_5, _toDisplayString(x.label) + " (" + _toDisplayString(x.quantity) + ") ", 1)
        ], 40, _hoisted_3))
      }), 128))
    ])
  ]))
}

import { defineComponent } from "vue";
import ListedItem from "@/core/helpers/listeditem";

var maxDistance = [
  [0, 1],
  [1, 5],
  [5, 10],
  [10, 15],
  [15, 20],
];

export default defineComponent({
  name: "DD-ziprad",
  props: ["customers", "markers", "refresh"],
  emits: ["customers-by-distance"],

  components: {},

  data() {
    return {
      miles: Array<ListedItem>(),
      calledFromOwn: false,
    };
  },
  watch: {
    refresh: function (newVal, oldVal) {
      // watch it
      this.miles.forEach((x) => (x.active = false));
    },
  },
  created: function () {
    maxDistance.forEach((x) => {
      this.miles.push(new ListedItem(x[1].toString() + " Miles", 0));
    });
    this.miles.push(new ListedItem("Uknown/Other", 0));
  },

  beforeUpdate() {
    if (!this.calledFromOwn) {
      this.miles.forEach((x) => {
        var index = this.getIndexByLabel(x.label);
        x.quantity = this.markers.filter((y) => y.distanceType == index).length;        
      });
    } else {
      this.calledFromOwn = false;
    }
  },

  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;
      var node = this.miles.find((x) => x.label == label);
      if (node == null) {
        return;
      }
      //node is always not null but if this condition is omitted then js produces errors below
      node.active = !node.active;

      if (this.miles.every((x) => !x.active || x.quantity == 0)) {
        this.calledFromOwn = false;
        this.$emit("customers-by-distance", this.customers);
        return;
      }
      var result = [];
      this.miles.forEach((x) => {
        var index = this.getIndexByLabel(x.label);
        if (x.active == true) {
          result = result.concat(
            this.customers.filter((y) => y.distanceType == index)
          );
        }
      });     
      this.$emit("customers-by-distance", result);
    },

    getIndexByLabel(label: string): number {
      switch (label) {
        case "1 Miles":
          return 0;
          break;
        case "5 Miles":
          return 1;
          break;
        case "10 Miles":
          return 2;
          break;
        case "15 Miles":
          return 3;
          break;
        case "20 Miles":
          return 4;
          break;
        default:
          return 5;
          break;
      }
    },
  },
});

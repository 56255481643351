
import { defineComponent, ref } from "vue";
import ListedItem from "@/core/helpers/listeditem";
import dateFuncs from "@/core/helpers/dateFuncs";

const labels = [
  "Lost - 0 Visits",
  "Retained - 1 Visit",
  "Loyal - 2+ Visits",
  "Flagged",
];

export default defineComponent({
  name: "DD-cs",

  props: ["customers", "markers", "refresh"],
  emits: ["customers-by-status"],

  watch: {
    refresh: function (newVal, oldVal) {
      // watch it
      this.statusList.forEach((x) => (x.active = false));
    },
  },

  data() {
    return {
      statusList: ref(Array<ListedItem>()),
      calledFromOwn: false,
    };
  },
created: function () {
    labels.forEach(l => this.statusList.push(new ListedItem(l, 0)));
    this.statusList.push(new ListedItem("Other", 0));
  },

  beforeUpdate() {
    if (!this.calledFromOwn) {
      //get unique status tags
      if (this.statusList.length == 0) {
        labels.forEach((l, index) => {
          this.statusList.push(
            new ListedItem(l, this.markers.filter((x) => x.status == index).length)
          );
        });
      } else {        
        labels.forEach((l,index) => {
          this.statusList[index].quantity = this.markers.filter(
            (x) => x.status == index
          ).length;
        });
      }      
    } else {
      this.calledFromOwn = false;
    }
  },
  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;

      var node = this.statusList.find((x) => x.label == label);
      if (node == null) {
        return;
      }
      //node is always not null but if this condition is omitted then js produces errors below
      node.active = !node.active;

      if (this.statusList.every((x) => !x.active || x.quantity == 0)) {
        this.calledFromOwn = false;
        this.$emit("customers-by-status", this.customers);
        return;
      }
     
      var result = [];
      this.statusList.forEach((s,index) => {           
            if (s.active)
            { 
              result = result.concat(this.customers.filter(c => c.status == index));
            }
      });
      this.$emit("customers-by-status", result);
    },
  },
});

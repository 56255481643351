import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-250px p-5",
  "data-kt-menu": "true"
}
const _hoisted_2 = ["onChange"]
const _hoisted_3 = { class: "form-check form-check-custom form-check-solid form-check-sm" }
const _hoisted_4 = ["checked", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "form-check-label fw-bolder fs-6"
}
const _hoisted_6 = {
  key: 1,
  class: "form-check-label fw-bolder fs-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roList, (x, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "mb-1",
        key: index,
        onChange: ($event: any) => (_ctx.checkHandler(x.label))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            value: "",
            checked: x.active  && x.quantity > 0,
            disabled: x.quantity == 0
          }, null, 8, _hoisted_4),
          (x.label != 'Undefined')
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(x.label + " Months (" + x.quantity + ")"), 1))
            : _createCommentVNode("", true),
          (x.label == 'Undefined')
            ? (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString(x.label + " (" + x.quantity + ")"), 1))
            : _createCommentVNode("", true)
        ])
      ], 40, _hoisted_2))
    }), 128))
  ]))
}
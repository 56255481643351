export default {
    inMonths: function (d2: Date, d1: number | Date | string) {

        var d1Arg: Date;

        if (typeof d1 == "number") {
            d1Arg = new Date(d1, 0, 1);
        } else if (typeof d1 == "string") {
            d1Arg = new Date(parseInt(d1), 0, 1);
        } else {
            d1Arg = d1;
        }
        var d1Y = d1Arg.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1Arg.getMonth();
        var d2M = d2.getMonth();

        return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },    
}

import { defineComponent, ref } from "vue";
import ListedItem from "@/core/helpers/listeditem";

export default defineComponent({
  name: "DD-year",
  components: {},
  props: ["customers", "markers", "refresh"],
  emits: ["customers-by-year"],

  data() {
    return {
      years: ref(Array<ListedItem>()),
      calledFromOwn: false,
      uniqueYears: [2017, 2018, 2019, 2020, 2021, 2022],
    };
  },

  watch: {
    refresh: function (newVal, oldVal) {
      // watch it
      this.years.forEach((x) => (x.active = false));
    },
  },

  created: function () {
    this.uniqueYears.forEach((x) => {
      this.years.push(new ListedItem(x.toString(), 0));
    });
    this.years.push(new ListedItem("Other", 0));
  },

  beforeUpdate() {
    if (!this.calledFromOwn) {
      this.years.forEach((y) => {        
        if (y.label != "Other") {
          y.quantity = this.markers.filter(
            (e) => e.year == Number(y.label)
          ).length;
        } else {
          y.quantity = this.markers.filter(
            (x) => x.year < 2017 || x.year > 2022
          ).length;
        }
        //if (y.quantity == 0) y.active = false;
      });
    } else {
      this.calledFromOwn = false;
    }
  },

  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;
      var node = this.years.find((x) => x.label == label);
      if (node == null) {
        return;
      }
      //node is always not null but if this condition is omitted then js produces errors below
      node.active = !node.active;

      if (this.years.every((x) => !x.active || x.quantity == 0)) {
        this.calledFromOwn = false;
        this.$emit("customers-by-year", this.customers);
        return;
      }

      var result = [];

      this.years.forEach((y) => {
        if (y.active) {
          if (y.label != "Other") {
            result = result.concat(
              this.customers.filter((c) => c.year == Number(y.label))
            );
          } else {
            result = result.concat(
              this.customers.filter((y) => y.year < 2017 || y.year > 2022)
            );
          }
        }
      });

      this.$emit("customers-by-year", result);
    },
  },
});

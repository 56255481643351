
import { defineComponent, ref } from "vue";
//import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import dateFuncs from "@/core/helpers/dateFuncs"

export default defineComponent({
  name: "customerdata",  
  components: {
    //Dropdown1,
  },
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    btnColor: String,
    customers:Object,
    customerCharts:Object
  },
  

  setup(props) {
    const color = ref(props.chartColor);

    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);
    const labelColor = getCSSVariableValue("--bs-" + "gray-700");

    const dwColor = ["#6966F0", "#22539F", "#0090FF", "#3A36DB", "#0F3774"];

    const chartOptions = {
      colors: ["#6966F0", "#22539F", "#0090FF", "#3A36DB"], //,"#0F3774"],
      stroke: {
        width: 0,
      },
      labels: ["0 Visits", "1 Visits", "Expiring", "Loyal"],
      dataLabels: {
        enabled: false,
      },
      chart: {
        type: "donut",
        width: "50%",
        offsetX: 0,
        offsetY: 0,
      },
      legend: {
        show: false,
        position: "right",
        offsetX: -30,
        offsetY: 70,
        formatter: (value, opts) => {
          return value + " - " + opts.w.globals.series[opts.seriesIndex];
        },
        markers: {
          onClick: undefined,
          offsetX: 0,
          offsetY: 25,
        },
      },
      plotOptions: {
        pie: {
          //offsetX: 0,
          //offsetY: 0,
          customScale: 1,
          dataLabels: {
            enabled: false,
          },
          startAngle: 10,
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "26px",
                fontFamily: "ProximaNova",
                fontWeight: 900,
                color: "#809FB8",
                offsetY: 28,
                formatter: function (val) {
                  return val;
                },
                //formatter: () => 'Completed'
              },
              value: {
                show: true,
                fontSize: "36px",
                fontFamily: "ProximaNova",
                fontWeight: 900,
                color: "#000000",
                offsetY: -10,
                formatter: function (val) {
                  return parseFloat(val).toLocaleString(); 
                },
              },
              total: {
                show: true,
                label: "",
                formatter: (w) => {
                  const total = w.globals.seriesTotals.reduce(
                    (a, b) => a + b,
                    0
                  );
                  return parseFloat(total).toLocaleString();;
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          expandOnClick: true,
        },
      },
    };

  var currentDate = new Date();

    var series = ref([props.customerCharts?.zeroVisit,props.customerCharts?.oneVisit,props.customerCharts?.loyal,props.customerCharts?.expiring]); 

    return {
      series,
      chartOptions,
    };
  },

  beforeUpdate() {
  var currentDate = new Date();

    var lost = 
        this.customers?.filter(
          (x) =>
            x.Visits == 0 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) > 12
        ).length;

    var retained = this.customers?.filter(
          (x) =>
            x.Visits == 1 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) > 12
        ).length;

    var loyal = this.customers?.filter(
          (x) =>
            x.Visits > 1 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) > 12        
      ).length;
    
    var flagged = this.customers?.filter((x) => x.IsFlagged == 1).length;
    
    var soldns = this.customers?.filter(
          (x) =>
            x.Visits == 0 &&
            x.IsFlagged == 0 &&
            dateFuncs.inMonths(currentDate, x.Year) <= 12
        ).length;

    this.series = [lost,retained,loyal,flagged,soldns]; 
  }
});

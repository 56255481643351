
import { defineComponent, ref, onMounted, computed, reactive } from "vue";
import CustomerBreakdown from "@/components/kpi/custbreakdownheatmap.vue";
import CustomerBreakdownModel from "@/components/kpi/custbreakdownmodel.vue";
import CustomerBreakdownYear from "@/components/kpi/custbreakdownyear.vue";


  export default defineComponent({
    name: 'ZipCard',
    emits:['close'],
    components: {
      CustomerBreakdown,
      CustomerBreakdownModel,
      CustomerBreakdownYear

    },
    props:['customers','zip'],

    setup(props) {
      var totalROs = 1;
      var totalRoSum = 0;
      return {
        totalROs,
        totalRoSum
      }
    },

    beforeUpdate() {
      this.totalROs = this.customers?.filter(x => x.LastRoDate != null && x.LastRoDate != '' && x.LastRoDate != 'null' && x.LastRoDate != 'NULL').length;
      
      this.totalRoSum = 0.0;
      this.customers?.forEach(x => {
        this.totalRoSum += x.Miles; //trick......
      });                  
    },

    methods: {
      close() {
        this.$emit('close');
      },
    },
  });


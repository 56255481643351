
import { defineComponent, onMounted, ref } from "vue";
import DWmap from "@/components/map/DWmap.vue";
import CustomerRetention from "@/components/kpi/custret.vue";
import CustomerData from "@/components/kpi/custdat.vue";
import FlaggedRecords from "@/components/kpi/flagrecords.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
//import customers from "@/core/data/mapcontactfinal";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dashboard",
  props: {
    mySource: String,
    myDealer: Number,
  },
  components: {
    DWmap,
    CustomerRetention,
    CustomerData,
    FlaggedRecords,
  },
  data(){
    return {
      customerCharts: null as any,
      seriesFlagged : [1,1,1,1,1],
      refreshKey : ref(0)
    }
  },
  watch: {
    myDealer(newValue, oldvalue) {
      //console.log("Selected Source is now", this.mySource);
      //console.log("Selected dealerid is now", newValue);
      if (this.mySource) this.fetchData(newValue, this.mySource);
    },
    mySource(newValue, oldvalue) {
      //console.log("Selected dealerid is now", this.myDealer);
      //console.log("Selected Source is now", newValue);
      if (this.myDealer) this.fetchData(this.myDealer, newValue);
    },
  },
  methods: {
    fetchData(myDealer:any, mySource:any){
      ApiService.axios.get(`/Customer/GetCustomerCharts?dealerId=${myDealer}&dataSource=${mySource}&forceWidget=false`).then(x => {
        this.customerCharts = x.data[0],
         this.refreshKey += 1
      }).catch(error => console.log(error))
    }
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });

    //var myCustomers = customers;
    //var myCustomers:ref([]) as any;

    return {
      myCustomers:ref([]) as any
    };
  },

  /*
  beforeUpdate() {
    this.myCustomers =
      this.mySource == "ALL"
        ? customers
        : customers.filter((x) => x.DataSource == this.mySource);
  },
  */
});

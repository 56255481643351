
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "flagrecords",
  components: {
    //Dropdown1,
  },
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    btnColor: String,
    customers: Object,
    customerCharts: Object,
  },
  setup(props) {
    //console.log(props.customers);
    const color = ref(props.chartColor);
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);
    const labelColor = getCSSVariableValue("--bs-" + "gray-700");

    const dwColor = ["#6966F0", "#22539F", "#0090FF", "#3A36DB", "#0F3774"];

     const chartOptions = {
      colors: ["#6966F0", "#22539F", "#0090FF", "#3A36DB"], //,"#0F3774"],
      stroke: {
        width: 0,
      },
      labels: ["Barcode", "Mail", "IHS", "Manual"],
      dataLabels: {
        enabled: false,
      },
      chart: {
        type: "donut",
        width: "50%",
        offsetX: 0,
        offsetY: 0,
      },
      legend: {
        show: false,
        position: "right",
        offsetX: -30,
        offsetY: 70,
        formatter: (value, opts) => {
          return value + " - " + opts.w.globals.series[opts.seriesIndex];
        },
        markers: {
          onClick: undefined,
          offsetX: 0,
          offsetY: 25,
        },
      },
      plotOptions: {
        pie: {
          //offsetX: 0,
          //offsetY: 0,
          customScale: 1,
          dataLabels: {
            enabled: false,
          },
          startAngle: 10,
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "26px",
                fontFamily: "ProximaNova",
                fontWeight: 900,
                color: "#809FB8",
                offsetY: 28,
                formatter: function (val) {
                  return val;
                },
                //formatter: () => 'Completed'
              },
              value: {
                show: true,
                fontSize: "36px",
                fontFamily: "ProximaNova",
                fontWeight: 900,
                color: "#000000",
                offsetY: -10,
                formatter: function (val) {
                  return parseFloat(val).toLocaleString(); 
                },
              },
              total: {
                show: true,
                label: "",
                formatter: (w) => {
                  const total = w.globals.seriesTotals.reduce(
                    (a, b) => a + b,
                    0
                  );
                  return parseFloat(total).toLocaleString();;
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          expandOnClick: true,
        },
      },
    };
    var series = [props.customerCharts?.barcode
    ,props.customerCharts?.mail
    ,props.customerCharts?.ihs     
    ,props.customerCharts?.manual];       

    return {
      series,
      chartOptions,
    };
  },
});


import { defineComponent } from "vue";
import ListedItem from "@/core/helpers/listeditem";

var labels = [
  "November 2021",
  "October 2021",
  "September 2021",
  "August 2021",
  "July 2021",
  "June 2021",
  "May 2021",
  "April 2021",
];

export default defineComponent({
  name: "DD-campaign",
  components: {},
  props: ["customers", "markers", "refresh"],
  emits: ["customers-by-campaign"],

  watch: {         
      	refresh: function(newVal, oldVal) { // watch it
          this.campaigns.forEach(x => x.active = false);
        }},
  

  setup(props, context) {    
    var campaigns = Array<ListedItem>();
    labels.forEach((x, index) => {
      var customerSet = props.customers.filter(x  =>
        new Date(x.LastRoDate).getMonth() == 11 - index);      
      var quantity = customerSet.length;
      campaigns.push(new ListedItem(x, quantity));
    });       

    return {
      campaigns,      
      calledFromOwn:false,      
    };
  },

  beforeUpdate() {

    if (!this.calledFromOwn) {    
      this.campaigns.forEach((x, index) => {
        x.quantity = this.markers.filter(y  =>
          new Date(y.LastRoDate).getMonth() == 11 - index).length;
      });            
    } else {
      this.calledFromOwn = false;                 
    }
    this.campaigns.forEach((x) => {
      if (x.quantity == 0) {
        x.active = false;
      }
    });
  },
  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;
      var node = this.campaigns.find((x) => x.label == label);
      if (node == null) {
        return;
      }
      node.active = !node.active;
      if (this.campaigns.every((x) => !x.active)) {
         this.calledFromOwn = false;
        this.$emit("customers-by-campaign", this.customers);
        return;
      }

      var result=[];

      this.campaigns.forEach((x, index) => {
        if (x.active == true) {
          result = result.concat(
            this.customers.filter(y =>
              new Date(y.LastRoDate).getMonth() == 11 - index)
          );
        }
      });      
      
      if (result.length == 0) {
        result = this.customers;
        this.calledFromOwn = false;
      }      
      this.$emit("customers-by-campaign", result);
    },
  },
});

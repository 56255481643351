import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card bdr-p-none card-px-0" }
const _hoisted_2 = { class: "card-body bdr-p-none d-flex flex-column pb-3" }
const _hoisted_3 = { class: "flex-grow-1" }
const _hoisted_4 = { class: "d-flex flex-column flex-row-fluid" }
const _hoisted_5 = { class: "d-flex flex-row flex-column-fluid" }
const _hoisted_6 = { class: "d-flex flex-row-fluid flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_apexchart, {
                options: _ctx.chartOptions,
                series: _ctx.series,
                width: 220
              }, null, 8, ["options", "series"])
            ])
          ])
        ])
      ])
    ])
  ]))
}
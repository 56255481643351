
import { defineComponent, ref } from "vue";
import ListedItem from "@/core/helpers/listeditem";
import dateFuncs from "@/core/helpers/dateFuncs";

export default defineComponent({
  name: "DD-lastro",
  components: {},
  props: ["customers", "markers", "refresh"],
  emits: ["customers-by-ro"],
  watch: {
    refresh: function (newVal, oldVal) {
      // watch it
      this.roList.forEach((x) => (x.active = false));
    },
  },

  data() {
    return {
      roList: Array<ListedItem>(),
      calledFromOwn: false,
      //customersByRo: this.customers,
    };
  },

  created: function () {
    var currentDate = new Date();

    this.roList.push(new ListedItem("0-3", 0));
    this.roList.push(new ListedItem("3-6", 0));
    this.roList.push(new ListedItem("6-12", 0));
    this.roList.push(new ListedItem("12-18", 0));
    this.roList.push(new ListedItem("18-24", 0));
    this.roList.push(new ListedItem("24-36", 0));
    this.roList.push(new ListedItem("36-48", 0));
    this.roList.push(new ListedItem("48+", 0));

    this.roList.push(new ListedItem("Undefined", 0));
  },

  beforeUpdate() {
    if (!this.calledFromOwn) {      
      this.roList[0].quantity = this.markers.filter(
        (x) => x.lastRo == 0
      ).length;
      this.roList[1].quantity = this.markers.filter(
        (x) => x.lastRo == 1
      ).length;
      this.roList[2].quantity = this.markers.filter(
        (x) => x.lastRo == 2
      ).length;
      this.roList[3].quantity = this.markers.filter(
        (x) => x.lastRo == 3
      ).length;
      this.roList[4].quantity = this.markers.filter(
        (x) => x.lastRo == 4
      ).length;
      this.roList[5].quantity = this.markers.filter(
        (x) => x.lastRo == 5
      ).length;
      this.roList[6].quantity = this.markers.filter(
        (x) => x.lastRo == 6
      ).length;
      this.roList[7].quantity = this.markers.filter(
        (x) => x.lastRo == 7
      ).length;
      this.roList[8].quantity = this.markers.filter(
        (x) => x.lastRo == 8
      ).length;
    } else {
      this.calledFromOwn = false;
    }    
  },
  methods: {
    checkHandler(label: string) {
      this.calledFromOwn = true;
      var index = this.getIndexByLabel(label);
      var node = this.roList[index];
      if (node == null) {
        return;
      }
      //node is always not null but if this condition is omitted then js produces errors below
      node.active = !node.active;

      if (this.roList.every((x) => !x.active || x.quantity == 0)) {
        this.calledFromOwn = false;
        this.$emit("customers-by-ro", this.customers);
        return;
      }
      var result = [];      

      this.roList.forEach((x) => {
        if (x.active == true) {
          result = result.concat(
            this.customers.filter(
              (c) => c.lastRo == this.getIndexByLabel(x.label)
            )
          );
        }
      });
      if (result.length == 0) {
        this.calledFromOwn = false;
        result = this.customers;
      }
      this.$emit("customers-by-ro", result);
    },

    getIndexByLabel(label: string): number {
      var index = 8;

      switch (label) {
        case "0-3":
          index = 0;
          break;
        case "3-6":
          index = 1;
          break;
        case "6-12":
          index = 2;
          break;
        case "12-18":
          index = 3;
          break;
        case "18-24":
          index = 4;
          break;
        case "24-36":
          index = 5;
          break;
        case "36-48":
          index = 6;
          break;
        case "48+":
          index = 7;
          break;
        case "Undefined":
          index = 8;
          break;
      }
      return index;
    },

    getIndexByMonths(months: number): number {
      if (months <= 3) {
        return 0;
      }
      if (months <= 6) {
        return 1;
      }
      if (months <= 12) {
        return 2;
      }
      if (months <= 18) {
        return 3;
      }
      if (months <= 24) {
        return 4;
      }
      if (months <= 36) {
        return 5;
      }
      if (months <= 48) {
        return 6;
      }
      return 7;
    },
  },
});

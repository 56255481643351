export default class ListedModel {
    label: string;
    quantity: number;
    active: boolean;

    constructor(k: string, q: number, a: boolean=false) {        
        this.label = k;
        this.quantity = q;        
        this.active = a;
    }
}